import(/* webpackMode: "eager" */ "/home/runner/_work/frontends/frontends/node_modules/.pnpm/next@14.2.10_@babel+core@7.19.1_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_nmhqxcn7x55hr3vlkzfmw2t3pu/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/frontends/frontends/node_modules/.pnpm/next@14.2.10_@babel+core@7.19.1_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_nmhqxcn7x55hr3vlkzfmw2t3pu/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/frontends/frontends/node_modules/.pnpm/next@14.2.10_@babel+core@7.19.1_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_nmhqxcn7x55hr3vlkzfmw2t3pu/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/frontends/frontends/node_modules/.pnpm/next@14.2.10_@babel+core@7.19.1_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_nmhqxcn7x55hr3vlkzfmw2t3pu/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/frontends/frontends/node_modules/.pnpm/next@14.2.10_@babel+core@7.19.1_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_nmhqxcn7x55hr3vlkzfmw2t3pu/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/_work/frontends/frontends/node_modules/.pnpm/next@14.2.10_@babel+core@7.19.1_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@18_nmhqxcn7x55hr3vlkzfmw2t3pu/node_modules/next/dist/client/components/render-from-template-context.js");
